import config from "../config/config"
import { ModelConfig } from "../types/ModelConfig"

const exportRelationsExcel = async (
  fileName: string = "user_data.xlsx",
  models: ModelConfig[],
) => {
  const body = JSON.stringify({
    models,
    fileName,
  })

  const response = await fetch(
    `${config.REST_URL}/export/excel`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    },
  )

  if (response.ok) {
    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  } else {
    console.error("Export failed:", await response.json())
  }
}

export default exportRelationsExcel
